import './faq.scss';

import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout/SplitLayout';
import Markdown from 'components/core/Markdown';

interface Props {
  data: {
    faq: {
      questions: {
        id: string;
        slug: string;
        question: string;
        answer: string;
      }[];
    };
  };
}

export default class FAQPage extends React.Component<Props> {
  render() {
    const { questions } = this.props.data.faq;

    return (
      <Layout>
        <div className="FAQPage">
          <h1>Frequently Asked Questions</h1>
          {questions.map((question) => (
            <div key={question.id} id={question.slug}>
              <h2>{question.question}</h2>
              <Markdown>{question.answer}</Markdown>
            </div>
          ))}
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query FAQ {
    faq: allFaqsYaml {
      questions: nodes {
        id
        question
        slug
        answer
      }
    }
  }
`;
